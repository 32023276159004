class ArticleFilterForm {
    constructor() {
        this._filterForm = document.querySelector('.js-article-filter-form');

        if (!this._filterForm) {
            return;
        }

        this._initEventListeners();
    }

    _initEventListeners() {
        this._filterForm.addEventListener('change', this._filterForm.submit);
    }
}

export default ArticleFilterForm;
