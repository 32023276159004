import $ from 'jquery';

class Faq {
    constructor() {
        this.setFaq();
    }

    setFaq() {
        $('.list__item--faq').click(function (e) {
            e.preventDefault();

            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $('.block-faq__content:visible').slideUp(300);

                return false;
            }

            const currentHref = $(this).attr('href');
            if ($(e.target).is('.active')) {
                $(this).removeClass('active');
                $('.block-faq__content:visible').slideUp(300);
            } else {
                $('.list__item--faq').removeClass('active').filter(this).addClass('active');
                $('.block-faq__content').slideUp(300).filter(currentHref).slideDown(300);
            }
        });
    }
}

export default Faq;
