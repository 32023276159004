import $ from 'jquery';
import { BREAKPOINTS } from "../constants/breakpoints";

// need to be set directly to overwrite inline styles
export function setWysiwygStyles() {
    const viewportWidth = $(window).width();

    if (viewportWidth < BREAKPOINTS.md) {
        const $content = $('.wysiwyg')
        const $figures = $content.find('figure')
        $figures.each(function() {
            $(this).css({"margin": "0", "float": "none", "padding": "1rem 0"})
            $(this).children("img").css("width", "100%")
        })
    }
}
