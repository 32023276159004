import $ from 'jquery';
import Cookie from 'js-cookie';

export default class SpecialistPicker {
    constructor(selector = '.js-specialist-picker') {
        this.$elems = $(selector);
        this._selectSelector = '.js-specialist-select';
        this._specialistSelector = '.js-specialist-picker-item';

        if (!this.$elems.length) {
            return;
        }

        this.$elems.each((i, specialistPicker) => this._initPicker($(specialistPicker)));
    }

    _initPicker($specialistPicker) {
        const $select = $specialistPicker.find(this._selectSelector);
        const $specialists = $specialistPicker.find(this._specialistSelector);

        this._setActiveSpecialist($select.val(), $specialists);

        $select.on('change', this._handleSelectChange.bind(this, $specialists));
        $specialists.on('click', 'a', this._updateMemberFirmId.bind(this, $specialists));
    }

    _setActiveSpecialist(provinceId, $specialists) {
        $specialists.removeClass('is-active');

        const $activeSpecialist = $specialists.filter((i, specialists) => {
            return JSON.stringify($(specialists).data('province-id')) === provinceId;
        });

        $activeSpecialist.addClass('is-active');
    }

    _handleSelectChange($specialists, e) {
        const memberFirmId = $(e.currentTarget).val();

        this._setActiveSpecialist(memberFirmId, $specialists);
    }

    _updateMemberFirmId($specialists) {
        const memberFirmId = $specialists.filter('.is-active').data('member-firm-id');
        const COOKIE_NAME = 'last_visited_member_firm_id';

        // Keep in sync with MemberFirmVisitTracker setup
        Cookie.set(COOKIE_NAME, memberFirmId, { secure: true });
    }
}
