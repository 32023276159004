import $ from 'jquery';

export const SELECTOR = '.newsletter__subscription__block';
export const NEWSLETTER_STORAGE_KEY = 'hide_newsletter';
export const NEWSLETTER_HIDE_CLASS = 'js-hidden';

export default class NewsletterSubscriptionBlock {
    constructor(selector = SELECTOR) {
        this.$elem = $(selector)[0];
        const hideBlock = localStorage.getItem(NEWSLETTER_STORAGE_KEY);
        // hide block by default
        if (!hideBlock) this._showBlock(this.$elem);

        $('.js-close-newsletter').on('click', this._onCloseClick.bind(this));
        $('.js-newsletter-button').on('click', this._onButtonClick);
    }

    _onButtonClick() {
        localStorage.setItem(NEWSLETTER_STORAGE_KEY, 'true');
        this._hideBlock(this.$elem);
    }

    _onCloseClick(event) {
        event.preventDefault();
        localStorage.setItem(NEWSLETTER_STORAGE_KEY, 'true');
        this._hideBlock(this.$elem);
    }

    _showBlock(elem) {
        $(elem).removeClass(NEWSLETTER_HIDE_CLASS);
    }

    _hideBlock(elem) {
        $(elem).addClass(NEWSLETTER_HIDE_CLASS);
    }
}
