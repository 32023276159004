// polyfills
// note: react has a polyfill problem when building in dev running IE11 but it should be fine in production
import 'core-js/features/promise';
import 'core-js/features/object/assign';

// libs
import $ from 'jquery';
import validate from 'jquery-validation';
import FHCookieGuard from '@freshheads/cookie-guard';

// modules
import Header from './modules/Header';
import MemberFirmNavigation from './modules/MemberFirmNavigation';
import ScrollSpy from './modules/ScrollSpy';
import ScrollTo from './modules/ScrollTo';
import Sticky from './modules/Sticky';
import ArticleFilterForm from './modules/ArticleFilterForm';
import SpecialistPicker from './modules/SpecialistPicker';
import FadeInOnScroll from './modules/fade-in-on-scroll';
import EmployeeModal from './modules/EmployeeModal';
import Faq from './modules/Faq';
import { setBrowserClassToHTML } from './modules/DetectBrowserLoader';
import ToggleBlock from "./modules/ToggleBlock";
import Carousel from "./modules/Carousel";
import {setWysiwygStyles} from "./modules/WysiwygStyles";
import NewsletterSubscriptionBlock from "./modules/NewsletterSubscriptionBlock"

function loadMemberFirmMap() {
    var memberFirmMapContainerElement = document.querySelector('.js-member-firm-map');

    if (!memberFirmMapContainerElement) {
        return;
    }

    require.ensure([], (require) => require('./memberFirmMap'), 'member-firm-map');
}

$(() => {
    validate,
    setBrowserClassToHTML();
    setWysiwygStyles();
    new Header();
    new MemberFirmNavigation();
    new ScrollSpy();
    new ScrollTo();
    new Sticky();
    new Carousel();
    new ToggleBlock();
    new ArticleFilterForm();
    new SpecialistPicker();
    new FadeInOnScroll;
    new EmployeeModal();
    new Faq();
    new FHCookieGuard('.js-cookie-alert');
    loadMemberFirmMap();
    new NewsletterSubscriptionBlock();
});
