import $ from 'jquery';
import raf from 'raf';

export default class FadeInOnScroll {
    constructor(options = {}) {
        this._options = Object.assign({
            selector: '.js-fade-in-on-scroll',
            windowHeightMultiplier: 0.5
        }, options);

        this._$fadeInElements = $(this._options.selector);

        if (!this._$fadeInElements.length) {
            return;
        }
        this._$fadeInElements.each((index, elem) => this.initializeFadeInElement(elem));
    }

    /**
     * @param {object} elem
     */
    initializeFadeInElement(elem) {
        const $elem = $(elem),
              $window = $(window),
              positionFadeIn = ($elem.data('window-height-multiplier') || this._options.windowHeightMultiplier) * $window.height();

        let currentScrollTop = $window.scrollTop();

        raf(function tick() {
            const newScrollTop = $window.scrollTop();

            if (currentScrollTop !== newScrollTop) {
                currentScrollTop = newScrollTop;
                $elem.toggleClass('fade-in', $window.scrollTop() > positionFadeIn);
            }

            raf(tick.bind(this));
        }.bind(this));
    }
}
