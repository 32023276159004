import $ from 'jquery';
import tingle from '../tingle.min';

export default class EmployeeModal {
    constructor(selector = '.js--employee') {
        this.$elems = $(selector);

        if (!this.$elems.length) {
            return;
        }

        this.$elems.each((i, employee) => this._initModal($(employee)));
    }

    _initModal($employee) {
        const modal = new tingle.modal({
            footer: false,
            stickyFooter: false,
            closeMethods: ['overlay', 'button', 'escape'],
            closeLabel: 'Close'
        });

        // set content
        modal.setContent($employee.find('.employee__popup')[0]);

        $employee.find('.js--employee-button').on('click', (e) => {
            e.preventDefault;
            modal.open();
        });
    }
}
