import $ from 'jquery';

class Header {
    constructor() {
        $('.js-toggle-menu').on('click', this._onToggleMenuClick.bind(this));
    }

    _onToggleMenuClick() {
        $('body').toggleClass('mobile-nav-is-active');
    }
}

export default Header;
