import $ from 'jquery';
import { BREAKPOINTS } from '../constants/breakpoints';

class Sticky {
    constructor() {
        this.$sticky = $('.js-sticky');

        if (this.$sticky.length === 0) {
            return;
        }

        require.ensure([], () => {
            require('sticky-kit/dist/sticky-kit');

            this._create();
        });
    }

    _create() {
        this.$sticky.each((index, element) => {
            var $element = $(element);

            var options = $.extend({
                sticky_class: 'is-stuck' // eslint-disable-line camelcase
            }, $element.data());

            $element.stick_in_parent(options);
            this._destroyOnMobile($element);
        });
    }

    _destroyOnMobile($element) {
        if ($(window).width() < BREAKPOINTS.lg) {
            $element.trigger('sticky_kit:detach');
        }
    }
}

export default Sticky;
