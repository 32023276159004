import $ from 'jquery';
import { BREAKPOINTS } from '../constants/breakpoints';

export default class Carousel {
    constructor() {
        require.ensure([], () => {
            require('slick-carousel');

            this._init();
        });
    }

    _init() {
        $('.carousel-customer').slick({
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 4,
            infinite: true,
            lazyLoad: 'ondemand',
            responsive: [
                {
                    breakpoint: BREAKPOINTS.sm,
                    settings: {
                        arrows: false,
                        dots: true,
                        slidesToShow: 1,
                    }
                },
                {
                    breakpoint: BREAKPOINTS.md,
                    settings: {
                        slidesToShow: 3,
                    }
                }
            ]
        })

        $('.carousel-quotes').slick({
            autoplay: true,
            autoplaySpeed: 6000,
            slidesToShow: 1,
            infinite: true,
            lazyLoad: 'ondemand',
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: BREAKPOINTS.sm,
                    settings: {
                        arrows: false,
                        dots: true,
                    }
                },
            ]
        })

        $('.carousel-spotlight').slick({
            autoplay: true,
            autoplaySpeed: 6000,
            slidesToShow: 1,
            infinite: true,
            lazyLoad: 'ondemand',
            adaptiveHeight: false,
            dots: true,
        })
    }
}
