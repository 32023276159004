import $ from 'jquery';
import jump from 'jump.js';
import imagesLoaded from 'imagesloaded';

class ScrollTo {
    constructor() {
        imagesLoaded.makeJQueryPlugin($);

        $('.js-scroll-to').on('click', this._onClick.bind(this));
    }

    _onClick(event) {
        event.preventDefault();

        var $button = $(event.target);
        var data = $button.data();

        this.target = data.target;
        this.options = $.extend({
            callback: this._updateUrlHash(this.target)
        }, data);
        this.tries = 0;

        setTimeout(() => this._scrollToTarget(), 800);
    }

    _updateUrlHash(targetHash) {
        if (history.pushState) {
            history.pushState(null, null, targetHash);
        } else {
            location.hash = targetHash;
        }
    }

    /**
     * Checks if images are being preloaded by lazysizes
     * In that case we have to wait because the pages can still change in height
     *
     * @private
     */
    _scrollToTarget() {
        const WAITING_PERIOD = 1000;
        const MAX_TRIES = 5;

        $('img.lazypreload').imagesLoaded()
            .done(() => {
                this.tries = 0;
                jump(this.target, this.options);
            })
            .fail(() => {
                this.tries = this.tries + 1;

                if (this.tries <= MAX_TRIES) {
                    setTimeout(() => this._scrollToTarget(), WAITING_PERIOD);
                } else {
                    this._forceRedirect();
                }
            });
    }

    _forceRedirect() {
        window.location.hash = this.target;
        location.reload();
    }
}

export default ScrollTo;
