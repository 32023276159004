import $ from 'jquery';

const SELECTOR = {
    NAVIGATION_CONTAINER: '.member-firm-nav',
    SELECT: '.member-firm-nav__select'
};

class MemberFirmNavigation {
    constructor() {
        this._$navContainer = $(SELECTOR.NAVIGATION_CONTAINER);

        if (this._$navContainer.length === 0) {
            return;
        }

        this._createFallbackSelect();
    }

    /**
     * @private
     */
    _createFallbackSelect() {
        this._createFallbackSelectOptions();

        var select = `
            <div class="form form--light member-firm-nav__select">
                <select class="form-select">
                    ${ this.selectOptions }
                </select>
            </div>
        `;

        this._$navContainer.append(select);

        $(SELECTOR.SELECT).on('change', this._refreshOnChange.bind(this));
        $(window).on('resize', this._onWindowResize.bind(this)).trigger('resize');
    }

    /**
     * @private
     */
    _createFallbackSelectOptions() {
        this.selectOptions = '<option selected disabled>Snel naar</option>';

        this._$navContainer.find('a').each((index, element) => {
            var $anchor = $(element),
                text = $anchor.text(),
                target = $anchor.data('target') || $anchor.attr('href');

            this.selectOptions += `<option value="${target}" ${location.pathname === target ? 'selected="selected"' : ''}>${text}</option>`;
        });
    }

    /**
     * @param {Object} event
     * @private
     */
    _refreshOnChange(event) {
        var url = $(event.target).val();

        if (url) {
            window.location = url;
        }
    }

    /**
     * @private
     */
    _onWindowResize() {
        this._$navContainer.removeClass('has-select');

        var firstMenuItem = this._$navContainer.find('li:first-child');
        var lastMenuItem = this._$navContainer.find('li:last-child');

        // check if last item is on another row, if so show select
        if ((firstMenuItem.offset().top + firstMenuItem.height()) <= lastMenuItem.offset().top) {
            this._$navContainer.addClass('has-select');
        }
    }
}

export default MemberFirmNavigation;
