import $ from 'jquery'

class ToggleBlock {
    constructor() {
        this._setToggle();
    }

    _onToggleButtonClick() {
        $(this).slideToggle('slow', function() {
            const $toggleBlock = $(this);
            const $toggleButton = $(this).next();
            if ($toggleBlock.is(":visible")) {
                $toggleBlock.css('display','inline-block')
                $toggleButton.find('.block-toggle__icon').text('-')
                $toggleButton.find('.block-toggle__text').text('Minder informatie')
            } else {
                $toggleButton.find('.block-toggle__icon').text('+')
                $toggleButton.find('.block-toggle__text').text('Meer informatie')
            }
        })
    }

    _getToggleButton() {
        return `
            <div class="block-toggle__button">
                <div class="block-toggle__divider">
                    <span class="block-toggle__icon">+</span>
                    <span class="block-toggle__text">Meer informatie</span>
                </div>
            </div>
        `
    }

    _setToggle() {
        const getToggleButton = this._getToggleButton;
        const onToggleButtonClick = this._onToggleButtonClick;

        $('.block-toggle').each(function() {
            const $toggleButton = $(getToggleButton());
            $( this ).after($toggleButton);
            $toggleButton.on('click', onToggleButtonClick.bind(this));
        });
    }
}

export default ToggleBlock;
