import $ from 'jquery';

const ACTIVE_CLASS = 'is-active';

class ScrollSpy {
    constructor() {
        this.$nav = $('.js-scroll-spy');

        if (this.$nav.length === 0) {
            return;
        }

        $(document).on('scroll', this._onScroll.bind(this)).trigger('scroll');
    }

    _onScroll() {
        var scrollPos = $(window).scrollTop(),
            $links = this.$nav.find('[data-target]');

        $links.each((index, element) => {
            var $currLink = $(element),
                $refElement = $($currLink.attr('data-target'));

            if (typeof $refElement.position() === 'undefined') {
                return;
            }

            if ($refElement.offset().top <= scrollPos) {
                $links.removeClass(ACTIVE_CLASS);
                $currLink.addClass(ACTIVE_CLASS);
            } else {
                $currLink.removeClass(ACTIVE_CLASS);
            }
        });
    }
}

export default ScrollSpy;
